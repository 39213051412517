@import url('https://fonts.googleapis.com/css?family=Mulish:400,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import 'app_variables';
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "~flatpickr/dist/flatpickr.min.css";
@import "~magnific-popup";

html {
    overflow-x: hidden;
}
footer {
    color: #fff;
    position: relative;
    height: calc(100vh - 105px);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 8rem;
    .container {
        a, span, p {
            opacity: 0.9;
        }
    }
    a:not(.gdpr-link) {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .previo {
        color: $primary;
        &:hover {
            color: $primary;
        }
    }
    .box {
        background-color: $secondary;
        padding: 3rem;
        height: 100%;
        &:not(#sub) {
            margin-right: 1.5rem;
        }
        .menu-links {
            display: flex;
            flex-flow: column;
            justify-content: center;
        }
        .booking {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .copy {
        position: relative;
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 6rem;
        opacity: 0.9;
        font-size: $font-size-base * 0.8;
        span:first-child {
            margin-right: 2rem;
        }
    }
}
#map {
    position: absolute;
    height: calc(100vh - 105px);
    width: 100%;
}
.btn {
    text-transform: uppercase;
    letter-spacing: 2px;
}
.form-check-input {
    margin-top: 0.2rem;
}
.input-group {
    input {
        border-right: none !important;
        color: $text-gray;
    }
    .form-control:focus {
        border-color: $border-gray;
        color: $text-gray;
    }
    .input-group-text {
        border-left: none !important;
    }
}
.inputs-holder {
    h1 {
        text-transform: uppercase;
    }
    input[type="submit"] {
        margin-top: 1.5rem;
    }
}
.form-control:disabled, .form-control[readonly] {
    background-color: $secondary;
}
hr {
    border-top: 1px solid $border-gray;
    &.short {
        max-width: 152px;
        margin-inline-start: 0;
    }
    &.primary {
        border-top: 1px solid $primary;
    }
}
a .sipka-vpravo {
    display: flex;
    align-items: center;
    svg {
        margin-left: 2rem;
        .a {
            transition: all 0.15s ease-in-out;
        }
    }
}
a:hover {
    text-decoration: none;
}
.cookie-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 10000;
    right: 1rem;
    bottom: 1rem;
    max-width: 400px;
    padding: 1rem 1.2rem;
    margin-right: 1rem;
    background-color: $primary;
    color: #fff;
    box-shadow: 0px 3px 12px -7px rgba(0, 0, 0, 0.65);
    font-size: 0.8rem;

    .text {
        margin-right: 1rem;
    }
}
.menu-open {
    .navbar-holder {
        width: 100%;
        .navbar-collapse {
            top: 0;
            min-height: 100vh;
            .navbar-nav {
                opacity: 1;
            }
        }
    }
}
.desktop {
    display: block;
}
.mobile {
    display: none;
}
.menu-open nav.my-navbar {
    display: block;
    visibility: visible;
    opacity: 1;
}
nav.my-navbar {
    visibility: hidden;
    position: absolute;
    background: $primary;
    opacity: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 16;
    width: 100%;
    height: 100%;
    img.nav-logo {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        height: 100%;
        opacity: 0.1;
    }
    ul {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        justify-content: center;
        margin-right: 8.5rem;
        margin-bottom: 0;
        list-style-type: none;
        height: 100%;
        .nav-item {
            margin-bottom: 1rem;
            .nav-link {
                color: #fff;
                display: inline;
                font-size: $font-size-base * 1.5;
                letter-spacing: 4px;
            }
        }
    }
}
h1, .h1 {
    text-transform: uppercase;
    letter-spacing: 8px;
}
h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    letter-spacing: 2px;
}
h4:not(.title), .h4:not(.title) {
    text-transform: uppercase;
    font-weight: 700;
}
h4.title, .h4.title {
    font-size: $font-size-base * 0.9;
}

.blue-nav {
    height: 105px;
    .top-nav {
        display: flex;
        justify-content: space-between;
        background: $secondary;
        position: fixed;
        right: unset;
        padding: 1.5rem;
        top: 0;
        left: 0;
        width: 100%;
        height: 105px;
        z-index: 17;
    }
    .my-navbar {
        position: fixed;
    }
}
#pages-home .blue-nav {
    height: unset;
    .my-navbar {
        position: absolute;
    }
    .top-nav {
        height: 105px;
        position: absolute;
        justify-content: flex-end;
        right: 4rem;
        background: transparent;
    }
}
.mfp-zoom-in {

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }

}
.top-nav {
    a {
        color: #fff;
        font-weight: 700;
        letter-spacing: 3px;
        font-size: $font-size-base * 0.8;
    }
    .fa-instagram {
        font-size: $font-size-base * 1.4;
    }
    .fa-facebook-f {
        font-size: $font-size-base;
    }
    .phone, .email {
        margin-right: 1.5rem;
        a {
           font-size: $font-size-base * 0.7;
        }
    }
    .instagram {
        margin-right: 1rem;
        line-height: 1;
    }
    .lang {
        margin: 0 2rem;
        a {
            padding: 0.5rem 1rem;
            box-shadow: 0 0 2px 0 rgba(255,255,255,1);
        }
    }
}
.line-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div.line {
        flex-grow: 1;
        border-bottom: 2px solid $primary;
        margin: 0 0 0 2rem;
        &.between {
            margin: 0 2rem;
        }
    }
    .btn {
        padding: 0.5rem 2.5rem;
    }
}
.top-space {
    margin-top: 5rem;
}
.line-dostupnost {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1.4rem 2rem;
    background-color: $secondary;
    color: #fff;
    h4 {
        margin-bottom: 0;
    }
    form {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2rem;
        align-items: center;
        .form-control {
            border: 2px solid $border-gray;
            height: auto;
        }
        ::placeholder {
            color: $text-gray;
            opacity: 1;
        };
        .form-control:disabled, .form-control[readonly] {
            background-color: $secondary;
        }
        .input-group-text {
            background-color: $secondary;
            border: 2px solid $border-gray;
        }
    }
}

#pages-auth {
    .logo-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
.bg-image {
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    top: 105px;
}
#pages-cennik {
    .bg-image {
        background-image: url("/images/cennik.png");
    }
}
#pages-dostupnost {
    .bg-image {
        background-image: url("/images/dostupnost.png");
    }
}
#pages-reservation {
    .bg-image {
        background-image: url("/images/rezervacia.png");
    }
}
#pages-novinky {
    .bg-image {
        background-image: url("/images/novinky.jpg");
    }
    .novinka-zoznam {
        margin-bottom: 1.5rem;
        a {
            .date {
                color: $text-gray;
                font-size: $font-size-base * 0.8;
            }
        }
    }
    .obsah {
        margin-top: 2rem;
    }
    .novinka-nadpis {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 100%;
    }
    .title-image img {
        padding-right: 2rem;
    }
    .novinka-holder {
        border: 2px solid rgba(#A27847, 10%);
        padding: 3rem;
    }
}
#pages-onas {
    .bg-image {
        background-image: url("/images/onas.jpg");
    }
    .cennik {
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .left {
        padding-right: 2rem;
    }
    .right {
        padding-left: 2rem;
    }
    .apartment-boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
        .apartment-box {
            position: relative;
            background: #fff;
            padding: 1.5rem;
            border: 1px solid rgba($primary, 10%);
            .apart-title {
                font-weight: bold;
                font-size: $font-size-base * 1.2;
                text-transform: uppercase;
                max-width: 250px;
                margin: 1.5rem auto;
                text-align: center;
                min-height: 52px;
            }
            .rezervovat {
                text-align: center;
            }
            .image {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    max-height: 200px;
                }
            }
        }
    }
    .nase-apartmany-title {
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
}
#pages-kontakt {
    .bg-image {
        background-image: url("/images/kontakt.png");
    }
    .tel-mail {
        display: flex;
        flex-flow: column;
        justify-content: center;
        color: $primary;
        font-weight: bold;
        font-size: $font-size-base * 1.1;
        i {
            margin-right: 0.5rem;
        }
        .tel {
            margin-bottom: 0.5rem;
        }
        & a:hover {
            color: $primary;
        }
    }
    .form-title {
        color: $primary;
        margin-top: 5rem;
        margin-bottom: 1.5rem;
        font-weight: bold;
    }
    .form-holder {
        border: 2px solid rgba($primary, 10%);
        padding: 4rem;
        input[type=text], input[type=email], textarea {
            border: none;
            border-bottom: 1px solid $primary;
            color: #000;
        }
        input[type=submit], .g-recaptcha {
            margin-top: 2rem;
        }
    }
}
.vlozeny-text {
    p:last-child {
        margin-bottom: 0;
    }
    p span {
        font-size: 1rem !important;
    }
}
#pages-gallery {
    .bg-image {
        background-image: url("/images/gallery.jpg");
    }
    .gallery-titles {
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        .gallery-title {
            cursor: pointer;
            font-weight: bold;
            color: #B5B5B5;
            transition: 0.1s color ease-in-out;
            &.active {
                color: $primary;
            }
        }
    }
    .images {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 2.5rem;
        grid-row-gap: 2.5rem;
        a {
            &.hide {
                display: none;
            }
        }
        img {
            transition: opacity 0.1s ease-in-out;
        }
    }
}
#pages-aktivity {
    .bg-image {
        background-image: url("/images/aktivity.png");
    }
    .category-title {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
        &:not(.first) {
            margin-top: 5rem;
        }
        h2 {
            color: $primary;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 0;
        }
        img {
            margin-right: 1rem;
        }
    }
    .activities {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
    .activity {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-last-child(2):nth-of-type(odd) {
            margin-bottom: 0;
        }
        .activity-text {
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 2rem;
            &>div:first-child {
                margin-bottom: 1rem;
            }
        }
    }
}
#pages-ski-jasna {
    .bg-image {
        background-image: url("/images/skijasna.png");
    }
    .left {
        padding-right: 1.5rem;
    }
    .right {
        padding-left: 1.5rem;
    }
    h5 {
        letter-spacing: 1px;
    }
}
.reservation-box {
    position: relative;
    background: #fff;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem;
    margin-top: 6rem;
    .line-holder {
        margin-bottom: 2rem;
        h2 {
            margin-bottom: 0;
        }
    }
}
#pages-apartment {
    .h1, h1 {
        color: #fff;
        text-align: center;
        padding-top: 8rem;
        margin-bottom: 1rem;
    }
}
#pages-apartment, #pages-apartment-detail {
    position: relative;
    &>.container {
        position: relative;
    }
    .bg-image {
        background-image: url("/images/apartmany.png");
    }
    .facilities {
        margin-top: 2rem;
        &>div {
            margin-bottom: 1rem;
        }
        img {
            margin-right: 1rem;
        }
        .bed {
            img {
                margin-left: 3px;
                margin-right: 1.2rem;
            }
        }
    }
    .apartment-box {
        position: relative;
        background: #fff;
        max-width: 1200px;
        padding: 1rem;
        margin-top: 8rem;
        &:nth-child(odd) {
            margin-left: auto;
        }
        .text {
            padding: 3rem 4rem;
            display: flex;
            justify-content: space-between;
            flex-flow: column;
        }
        .image {
            position: relative;
            .gallery-btn {
                position: absolute;
                bottom: 3rem;
                right: 3rem;
                a {
                    padding: 0.5rem 2.5rem;
                }
            }
            img {
                height: 100%;
                object-fit: cover;
                max-height: 550px;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        a {
            width: 206px;
            &.long {
                width: 230px;
            }
        }
    }
}
#pages-apartment-detail {
    .apartment-box {
        padding: 4rem;
        .image {
            img {
                max-height: 450px;
                width: 100%;
            }
            .gallery-btn {
                right: unset;
                left: 3rem;
            }
        }
    }
    .line-holder {
        margin-bottom: 2rem;
    }
    .detail-text {
        padding: 2rem 3rem;
    }
    .facilities {
        margin-top: 3rem;
    }
    .facility {
        margin-bottom: 1rem;
        img {
            max-width: 18px;
            max-height: 18px;
            margin-right: 0.5rem;
        }
    }
}
.box {
    padding: 2rem;
    border: 1px solid #1C2646;
    margin-bottom: 1.5rem;
    &.availability {
        text-align: center;
        h4 {
            margin-bottom: 1.5rem;
        }
        .from {
            margin-bottom: 1rem;
        }
        .to {
            margin-bottom: 2rem;
        }
    }
    .new-text {
        font-size: $font-size-base * 0.9;
        margin-top: 1.5rem;
    }
}
.arrows {
    display: flex;
    &>div {
        cursor: pointer;
    }
    .prev {
        margin-right: 1rem;
    }
}
.alert-danger {
    p {
        margin-bottom: 0;
    }
}
#sub {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    h4 {
        line-height: 1.5;
    }
    input[name="email"] {
        margin-bottom: 2rem;
        padding: 1.25rem 0.75rem;
    }
    input[type="submit"] {
        width: 100%;
    }
    ::placeholder {
        color: $text-gray;
        opacity: 1;
    };
    .form-control {
        background-color: $secondary;
    }
}
.home-mobile {
    display: none;
}
#pages-home {
    main {
        position: relative;
    }
    h1, .h1 {
        text-shadow: 1px 1px rgba(#000, 0.8);
        letter-spacing: 8px;
        color: #fff;
    }
    &.home-holder {
        display: flex;
    }
    aside {
        background: $secondary;
        width: 400px;
        max-height: 100vh;
        color: #fff;
        display: flex;
        flex-flow: column;
        overflow-y: scroll;
        .form-control {
            border: 2px solid $border-gray;
            height: auto;
        }
        ::placeholder {
            color: $text-gray;
            opacity: 1;
        };
        .form-control:disabled, .form-control[readonly] {
            background-color: $secondary;
        }
        .box.availability .input-group-append {
            cursor: pointer;
        }
        .input-group-text {
            background-color: $secondary;
            border: 2px solid $border-gray;
        }
        .content {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
        .logo-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4rem 0;
        }
        .copy-booking {
            margin-bottom: 2rem;
            text-align: center;
            .booking-logo {
                margin-bottom: 1rem;
            }
            .copy-text {
                font-size: 0.6rem;
            }
        }
        .box {
            width: 320px;
            .new-text {
                display: inline-block;
                max-height: 74px;
                overflow: hidden;
            }
            .arrows {
                margin-top: 1rem;
            }
        }
    }
    main {
        max-height: 100%;
        width: calc(100% - 400px);
        .carousel-item {
            max-height: 100vh;
            img {
                //width: 100vw;
                height: 101vh;
                object-fit: cover;
                object-position: 50% 50%;
            }
        }
        .carousel-holder {
            position: absolute;
            left: 4rem;
            bottom: 3.5rem;
            z-index: 15;
            .carousel-indicators {
                right: unset;
                margin-block-end: 0;
                padding-inline-start: 0;
                margin: 0;
                li {
                    width: 120px;
                    margin-left: 6px;
                    margin-right: 6px;
                    height: 4px;
                    &:not(.active) {
                        background-color: #1C2646;
                        opacity: 1;
                    }
                }
            }
            .carousel-text {
                margin-bottom: 50px;
                width: 100%;
                .btn {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}


.hamburger {
    z-index: 17;
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    &:focus{
        outline: none !important;
    }

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.is-active {
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            }
            @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
        }
    }
}
.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}
.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    &::before,
    &::after {
        content: "";
        display: block;
    }
    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}
.hamburger--squeeze {
    .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.075s 0.12s ease,
            opacity 0.075s ease;
        }

        &::after {
            transition: bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease,
                opacity 0.075s 0.12s ease;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease,
                transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}

@media only screen and (max-height: 750px) {
    #pages-home aside .logo-holder {
        margin: 2rem 0;
    }
}
@media only screen and (min-width: 768px) {
    .inputs-holder {
        margin-left: 1rem;
        max-width: 425px;
    }
    #pages-auth {
        .logo-holder {
            border-right: 1px solid rgba($border-gray, 0.5);
            margin-right: 1rem;
            max-width: 300px;
        }
    }
    .center-screen {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: darken($primary, 5%);
    }
    nav.my-navbar {
        transition: all 0.2s ease-in-out;
    }
}
@media only screen and (min-width: 991.98px) and (max-width: 1100px) {
    footer .box {
        padding: 1.5rem;
    }
}
@media only screen and (max-width: 991.98px) {
    #pages-gallery {
        .images {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    footer {
        height: auto;
        margin-top: 0;
        flex-flow: column;
        align-items: center;
        .container {
            opacity: 0.95;
        }
        .box {
            margin-right: 0;
            margin-bottom: 0;
            height: auto;
            text-align: center;
            &:not(#sub) {
                margin-right: unset;
            }
            .booking img {
                margin-top: 1.5rem;
                max-width: 40vw;
            }
        }
        #map {
            position: relative;
            height: 350px;
        }
        .copy {
            background: $secondary;
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 2rem;
            padding-top: 2rem;
            opacity: unset;
        }
    }
}
.under-map {
    margin-left: 4rem;
    margin-right: 4rem;
}
@media only screen and (min-width: 767.98px) and (max-width: 1075px) {
    #pages-onas .apartment-boxes .apartment-box .image img {
        max-height: 100px;
    }
    #pages-apartment-detail, #pages-apartment {
        margin-bottom: 5rem;
        h1, .h1 {
            padding-top: 5rem;
        }
        .apartment-box {
            margin-top: 5rem;
            .text {
                padding: 1.5rem 2rem;
            }
        }
        .buttons a {
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .top-nav {
        .phone, .email {
            display: none;
        }
    }
    nav.my-navbar {
        ul {
            margin-right: 5rem;
            .nav-item .nav-link {
                font-size: 1.4rem;
            }
        }
        img.nav-logo {
            display: none;
        }
    }

    #pages-home {
        h1, .h1 {
            font-size: 1.2rem;
        }
        main .carousel-holder {
            left: 2rem;
            bottom: 1.5rem;
            .carousel-indicators li {
                width: 80px
            }
        }
    }
    #pages-gallery {
        .gallery-titles {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 0.5rem;
            grid-row-gap: 0.5rem;
        }
        .images {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
        }
    }
}
@media only screen and (min-width: 900px) and (max-width: 1075px) {
    #pages-home {
        h1, .h1 {
            font-size: 2rem;
        }
    }
}
@media only screen and (max-width: 767.98px) {
    .cookie-bar {
        left: 1rem;
    }
    .inputs-holder {
        margin-top: 1rem;
    }
    .top-space {
        margin-top: 2rem;
    }
    #pages-auth {
        .logo-holder {
            border-bottom: 1px solid rgba($border-gray, 0.5);
            margin-bottom: 1rem;
        }
    }
    .home-slider.carousel {
        position: inherit;
    }
    .carousel-holder {
        display: none;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .copy-booking {
        margin-bottom: 3rem;
    }
    nav.my-navbar {
        position: fixed;
        img {
            z-index: -1;
            &.nav-logo {
                left: 50%;
            }
        }
        ul {
            margin-right: 0;
            margin-top: 40px;
            padding-inline-start: 0;
            align-items: center;
            .nav-item .nav-link {
                font-size: $font-size-base * 1.3;
            }
        }
    }
    footer .box {
        .menu-links {
            display: none;
        }
    }
    .reservation-box {
        margin-top: 0;
        padding: 2rem 1rem 1rem 1rem;
        .line-holder {
            justify-content: center;
            flex-flow: column;
            margin-bottom: 1rem;
            div.line {
                width: 100%;
                margin: unset;
                padding-top: 1rem;
            }
        }
    }
    .box-content {
        margin-top: 1.5rem;
        padding-right: 15px;
        padding-left: 15px;
    }
    .copy-booking {
        margin-bottom: 3rem;
    }
    .home-mobile {
        display: block;
    }
    #pages-home {
        .hamburger {
            padding: 3rem 2rem;
        }
        &.home-holder {
            display: block;
            height: 100%;
        }
        .carousel-item {
            height: 275px;
            width: 100%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        main .carousel-holder {
            .carousel-text {
                width: 100%;
            }
            .carousel-indicators li {
                width: 40px;
            }
        }
        aside {
            width: 100%;
            max-height: unset;
            overflow-y: visible;
            .content {
                max-width: 100%;
            }
            .box {
                width: 100%;
            }
            .logo-holder {
                display: none;
                margin: 0 0 0 1rem;
                justify-content: space-between;
                img {
                    max-width: 180px;
                }
            }
        }
    }
    #pages-gallery {
        .gallery-titles {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 0.5rem;
            grid-row-gap: 0.5rem;
        }
        .images {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
        }
    }
    #pages-onas {
        .nase-apartmany-title {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        .apartment-boxes {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            .apartment-boxe .apart-title {
                margin: 1rem auto;
            }
        }
        .left {
            padding-right: 0;
            img {
                margin-bottom: 1rem;
            }
        }
        .right {
            padding-left: 0;
        }
        .cennik {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
    #pages-kontakt {
        .tel-mail {
            margin-top: 1rem;
        }
        .form-title {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        .form-holder {
            padding: 1.5rem;
        }
    }
    #pages-aktivity {
        .category-title {
            margin-bottom: 1.5rem;
        }
        .activity {
            margin-bottom: 1rem;
            .activity-text > div:first-child {
                margin-bottom: 0.5rem;
            }
            .activity-text {
                padding: 0.5rem;
            }
        }
        .category-title:not(.first) {
            margin-top: 2rem;
        }
    }
    .others .h1 {
        padding-top: 2rem;
    }
    #pages-apartment {
        h1, .h1 {
            padding-top: 3rem;
        }
    }
    #pages-apartment, #pages-apartment-detail {
        margin-bottom: 4rem;
        .apartment-box {
            margin-top: 2rem;
            .text {
                padding: 1rem 1rem;
            }
            .image {
                margin-bottom: 1rem;
                .gallery-btn {
                    bottom: 1rem;
                    right: 1rem;
                }
                img {
                    max-height: 200px;
                    width: 100%;
                }
            }
        }
        .facilities > div {
            margin-bottom: 0.5rem;
        }
        .buttons {
            margin-top: 2rem;
            flex-flow: column;
            align-items: center;
            a:first-child {
                margin-bottom: 1rem;
            }
        }
    }
    #pages-apartment-detail {
        .apartment-box {
            padding: 1rem;
        }
        .line-holder {
            flex-flow: column;
        }
        .detail-text {
            padding: unset;
        }
        .line-dostupnost {
            form {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: 1rem;
                align-items: center;
            }
        }
    }
    #pages-ski-jasna {
        .zjazdovka {
            margin-bottom: 0.2rem;
            & div:nth-of-type(2) {
                text-align: center;
            }
        }
        .left {
            padding-right: 0;
        }
        .right {
            padding-left: 0;
        }
        .jasna-img {
            margin-top: 2rem;
        }
        .jasna-copy {
            margin-bottom: 2rem;
        }
    }
    #pages-novinky {
        #new_container {
            margin-top: 2rem;
        }
        .novinka-holder {
            padding: 1rem;
            .novinka-nadpis {
                margin-left: 0;
            }
            .obsah {
                margin-top: 1rem;
            }
            .title-image {
                text-align: center;
                img {
                    margin-bottom: 1rem;
                    max-height: 200px;
                }
            }
        }
    }
    .top-nav {
        top: 0;
        right: 0;
        position: inherit;
        .lang {
            margin-top: -4px;
            margin-left: 1rem;
            margin-right: 0.5rem;
            a {
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                padding-left: 0.4rem;
                padding-right: 0.3rem;
                box-shadow: 0 0 2px 0 rgba(255,255,255,1);
            }
        }
    }
}
@media only screen and (max-width: 576.98px) {
    #pages-gallery {
        .images {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
#previo-booking-iframe {
    width: 100%;
    overflow: hidden;
    border: 0px;
}

.cryptedmail:after {
    content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
